import { Colors, Skeleton } from '@ensdomains/thorin';
import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { getContractInstance, getNetworkID } from '../../common';
import { useNavigate, useParams } from 'react-router-dom';

const Container = styled.div(
  ({ theme }) => css`
    padding: ${theme.space['4']};
    background: ${theme.colors.backgroundSecondary};
    display: flex;
    flex-direction: column;
    gap: ${theme.space['2']};
    width: 100%;
    border-radius: ${theme.space['2']};
  `
);

const LineItem = styled.div<{ $color?: Colors }>(
  ({ theme, $color }) => css`
    display: flex;
    justify-content: space-between;
    line-height: ${theme.space['5']};
    color: ${$color ? theme.colors[$color] : theme.colors.textTertiary};
  `
);

const Total = styled(LineItem)(
  ({ theme }) => css`
    color: ${theme.colors.text};
  `
);

export type InvoiceItem = {
  label: string;
  value?: bigint;
  bufferPercentage?: bigint;
  color?: Colors;
};

const Invoice = ({ dateInYears }: any) => {
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [coin, setCoin] = useState('EDX');

  const params = useParams();
  const navigate = useNavigate();

  const items = [
    {
      label: `${dateInYears} year${dateInYears > 1 ? 's' : ''} registration`,
      value: price + coin,
      bufferPercentage: '5',
      color: 'primary',
    },
    {
      label: 'Est. network fee',
      value: `0.0010 ${coin}`,
      bufferPercentage: '10',
      color: 'secondary',
    },
  ];

  const getPrice = async () => {
    const networkID = await getNetworkID();

    try {
      if (networkID === 1995||networkID === 17000||networkID === 5424) {
        const { edxContract } = await getContractInstance();
        const domain = params.id?.split('.')[0];
        const price = await edxContract.rentPrice(
          domain,
          31536000 * dateInYears
        );
        const part = price.toString().split(',');
        const p = Number((part[0] / 1e18).toFixed(4));
        const t = Number((p + 0.001).toFixed(4));
        setPrice(p);
        setTotal(t);
          if(networkID === 17000||networkID === 1995)
            {setCoin('tEDX')

            }else{
              setCoin('EDX')
            }
      }
    } catch (e) {
      console.log(e);
      // navigate('/unsupportedNetwork');
    }
  };

  useEffect(() => {
    getPrice();
  }, [dateInYears]);

  return (
    <Container>
      {items.map(({ label, value, bufferPercentage, color }, inx) => (
        <LineItem data-testid={`invoice-item-${inx}`} key={label}>
          <div>{label}</div>
          <Skeleton loading={!value}>
            <div data-testid={`invoice-item-${inx}-amount`}>{value}</div>
          </Skeleton>
        </LineItem>
      ))}
      <Total>
        <div>Estimated total</div>
        <div data-testid="invoice-total">{total} {coin}</div>
      </Total>
    </Container>
  );
};

export default Invoice;
