import { ethers } from 'ethers';
import PublicResolverAbi from './abi/PublicResolverAbi.json';
import edxRegistrarControllerAbi from './abi/edxRegistrarControllerAbi.json';
import baseRegistrarABI from './abi/baseRegistrarAbi.json';
import nameWrapperAbi from './abi/nameWrapperAbi.json';
import bPayAbi from './abi/bPayAbi.json';

let provider,
  signer,
  walletAddress,
  network,
  networkId,
  resolverAddress,
  edxRegistrarControllerAddress,
  nameWrapperAddress,
  baseRegistrarAddress,
  bPayAddress,
  resolverContract,
  edxContract,
  baseRegistrarContract,
  nameWrapperContract,
  bPayContract,
  TLD,
  timer;


export async function getContractInstance() {
  try {
    provider = new ethers.providers.Web3Provider(window.ethereum);
    signer = provider.getSigner();
    walletAddress = await signer.getAddress();
    network = await provider.getNetwork();
    networkId = network.chainId;

    switch (networkId) {
      case 1995:
        resolverAddress = '0xBABbA6863273F245BD14042FccdDaeB60E883B33';
        edxRegistrarControllerAddress =
          '0x8dBA422f80922f3475498a988184279EEc21EA31';
        baseRegistrarAddress = '0xB20c4FcecFDAD841D0E1390d8DFC7F5F40eE0A80';
        nameWrapperAddress = '0x631B254e8C5167c1b8dE13117637f3631Fb214D0';
        bPayAddress='0x610fdE022C99cf661Aa0578DF9AC206a89434594';
        TLD = '.tedx';
        timer = 10;
        break;
      case 5424:
        resolverAddress = '0x4344E466e3B38EF4f728800dB8524170a05565B7';
        edxRegistrarControllerAddress =
          '0xc8CEebF83a7f923d2B1F1e43D04398f2b9056000';
        baseRegistrarAddress = '0x7fb64e083A9ca14B835C4e2f518b11E6A6554c0d';
        nameWrapperAddress = '0x36d6E9E9CE91729CA9C2D7c40F96734086B58e66';
        TLD = '.edx';
        timer = 60;
        break;
      case 17000:
        resolverAddress = '0xb9A2E49E60230064347A47419eED71ceEc5d788e';
        edxRegistrarControllerAddress =
          '0x144915FF1ee4eE920e06E5609e19ba34e5a17620';
        baseRegistrarAddress = '0x2355496B6B064a3939EfA29d4E00a482D6897E7b';
        nameWrapperAddress = '0xE96B9524Faa1660EAA8Bdb7eE28aFcd745c1032D';
        TLD = '.tedx';
        timer = 10;
        break;
      default:
        // console.error('Unsupported network');
        break;
    }

    resolverContract = new ethers.Contract(
      resolverAddress,
      PublicResolverAbi,
      signer
    );
    edxContract = new ethers.Contract(
      edxRegistrarControllerAddress,
      edxRegistrarControllerAbi,
      signer
    );
    baseRegistrarContract = new ethers.Contract(
      baseRegistrarAddress,
      baseRegistrarABI,
      signer
    );

    nameWrapperContract = new ethers.Contract(
      nameWrapperAddress,
      nameWrapperAbi,
      signer
    );
    bPayContract = new ethers.Contract(
      bPayAddress,
      bPayAbi,
      signer
    );
  } catch (error) {}
  return {
    resolverContract,
    edxContract,
    baseRegistrarContract,
    nameWrapperContract,
    bPayContract
  };
}

export async function getWalletAddress() {
  await getContractInstance();
  return walletAddress;
}

export async function checkConnection() {
  await getContractInstance();
  return !!walletAddress;
}

export async function getResolver() {
  await getContractInstance();
  return resolverAddress;
}

export async function getNetworkID() {
  await getContractInstance();
  return networkId;
}

export async function getTimer() {
  await getContractInstance();
  return timer;
}

export async function getTLD() {
  await getContractInstance();
  return TLD;
}

export async function fetchENSAddress(search) {
  try {
    await getContractInstance();
    const node = ethers.utils.namehash(search);
    let owner = await resolverContract['addr(bytes32)'](node);
    if (owner !== ethers.constants.AddressZero) {
      return owner;
    } else {
      return ethers.constants.AddressZero;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function fetchUrl() {
  const networkId = await getNetworkID();
  if (networkId === 1995) {
    return 'https://graphql.edexa.network/subgraphs/name/edns_testnet-subgraph/';
  } else if (networkId === 5424) {
    return 'https://graphql.edexa.network/subgraphs/name/edns-subgraph/';
  } else if (networkId === 17000) {
    return 'https://graphql.edexa.network/subgraphs/name/edns_holesky-subgraph/';
  }
}

export const colors = [
  '#80deea',
  '#81d4fa',
  '#a5d6a7',
  '#ffcc80',
];

export function generateRandomGradient(colors) {
  const getRandomIndex = () => Math.floor(Math.random() * colors.length);

  let randomIndex1 = getRandomIndex();
  let randomIndex2;
  let randomIndex3;

  do {
    randomIndex2 = getRandomIndex();
  } while (randomIndex1 === randomIndex2);

  do {
    randomIndex3 = getRandomIndex();
  } while (randomIndex3 === randomIndex1 || randomIndex3 === randomIndex2);

  return `linear-gradient(45deg, ${colors[randomIndex1]} 4.54%, ${colors[randomIndex2]} 59.2%, ${colors[randomIndex3]} 148.26%)`;
}

export function containsTwoDots(str) {
  const dotCount = (str.match(/\./g) || []).length;
  return dotCount >= 2;
}

export function isMetaMaskInstalled() {
  return Boolean(window.ethereum);
}